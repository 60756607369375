<template>
    <div>
        <page-title :icon=icon :heading="$t('settings.lang_reservationArchive')"
                    :subheading="$t('settings.lang_reservationArchive')"></page-title>
        <div class="app-main__inner">
            <archive-component/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import ArchiveComponent from "../../../components/reservation/archive/ArchiveComponent";


    export default {
        components: {
            ArchiveComponent,
            PageTitle,
        },
        data: () => ({
            icon: 'pe-7s-box1 icon-gradient bg-tempting-azure',
        })
    }
</script>
<template>
  <div class="main-card mb-3 card pa-0 ma-0">
    <div class="card-body pa-0">
      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : 'bg-white']" class="card-title card-header-tab card-header">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ $t('settings.lang_reservationArchive') }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">

          <div class="btn-actions-pane-right actions-icon-btn">
          </div>
        </div>
      </div>

      <v-container class="px-0 mx-0 pa-0" fluid>
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0" cols="12">

            <v-data-table :headers="headers" :items="this.reservations" :loading="loadData"
                          :no-data-text="$t('generic.lang_noEntriesFound')">

              <!--headers-->
              <template v-slot:header.date="{header}">
                <font-awesome-icon :icon="['fal', 'calendar']" class="text-muted" size="2x" style="font-size: 15px"/>
                <span class="ml-2">{{ header.text }}</span>
              </template>

              <template v-slot:header.name="{header}">
                <font-awesome-icon :icon="['fal', 'user']" class="text-muted" size="2x" style="font-size: 15px"/>
                <span class="ml-2">{{ header.text }}</span>
              </template>

              <template v-slot:header.code="{header}">
                <font-awesome-icon :icon="['fal', 'hashtag']" class="text-muted" size="2x" style="font-size: 15px"/>
                <span class="ml-2">{{ header.text }}</span>
              </template>

              <template v-slot:header.person="{header}">
                <font-awesome-icon :icon="['fal', 'users']" class="text-muted" size="2x" style="font-size: 15px"/>
                <span class="ml-2">{{ header.text }}</span>
              </template>

              <template v-slot:header.phone="{header}">
                <font-awesome-icon :icon="['fal', 'phone']" class="text-muted" size="2x" style="font-size: 15px"/>
                <span class="ml-2">{{ header.text }}</span>
              </template>


              <template v-slot:item.date="{item}">

                <div class="date">
                  <span class="binds"></span>
                  <span class="month">{{item.date + ' ' + item.time }}</span>
                  <span class="day text-muted font-weight-bolder">
                <span class="mx-2"><font-awesome-icon :icon="['fal', 'users']" class="text-muted" size="2x"
                                                      style="font-size: 16px"/>
            <span class="ml-2 font-size-sm">{{ item.person }}</span>
              </span>

                <span class="mx-2"><font-awesome-icon :icon="['fal', 'keynote']" class="text-muted" size="2x"
                                                      style="font-size: 16px"/>
            <span class="ml-2 font-size-sm">{{ item.tables.map((elt) => elt.name).join(',') }}</span>
              </span>

              </span>
                </div>
              </template>


              <template v-slot:item.name="{item}">
                <v-menu
                    v-if="item"
                    v-model="item.selectedOpen"
                    :close-on-content-click="false"
                    max-width="400"
                    offset-x
                    z-index="1"
                >
                  <template v-slot:activator="{on}">
                    <div v-on="on" class="actions">
                      <strong class="primary--text">{{ item.name }}</strong>
                      <br/>
                      <font-awesome-icon :icon="['fal', 'phone']" class="text-muted" size="2x" style="font-size: 15px"/>
                      <span class="text--black ml-1">
                {{ item.phone }}
              </span>
                      <br/>
                      <v-chip v-if="item.confirmed == true" color="success" x-small>
                        {{ $t('generic.lang_confirmed') }}
                      </v-chip>

                      <v-chip v-if="item.confirmed == false" color="warning" x-small>
                        {{ $t('generic.lang_unconfirmed') }}
                      </v-chip>
                    </div>
                    <div v-if="item.code" class="copy" @click="copy(item.code)">
                      <font-awesome-icon :icon="['fal', 'copy']" class="text-muted" size="2x" style="font-size: 14px"/>
                      <span class="font-weight-bold ml-1">{{ item.code }}</span>
                    </div>
                  </template>
                  <v-card
                      color="white"
                      flat
                      min-width="350px"
                      outlined
                  >
                    <v-toolbar
                        class="elevation-0 text--grey"
                        color="white"
                        dark
                        dense
                    >
                      <v-toolbar-title class="text-muted">
                        {{ item.resType_name }}
                      </v-toolbar-title>
                      <v-spacer></v-spacer>


                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon small @click="open_email(item)">
                            <font-awesome-icon :icon="['fal', 'envelope']" class="text-muted" size="2x"
                                               style="font-size: 21px"/>
                          </v-btn>
                        </template>
                        {{ $t('generic.lang_contactCustomer') }}
                      </v-tooltip>


                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon small @click="close(item)">
                            <font-awesome-icon :icon="['fal', 'window-close']" class="text-muted" size="2x"
                                               style="font-size: 21px"/>
                          </v-btn>
                        </template>
                        {{ $t('generic.lang_close') }}
                      </v-tooltip>

                    </v-toolbar>

                    <v-card-text>
                      <div class="pb-4">
                        <v-icon v-if="item.confirmed == true" class="pb-1" color="success" large>
                          stop
                        </v-icon>

                        <v-icon v-if="item.confirmed == false" class="pb-1" color="warning" large>
                          stop
                        </v-icon>
                        <span>

                <strong class="font-weight-bolder font-size-lg">{{ item.name }}</strong>

                                                            <br/>
                <span class="ml-8"><strong>{{ $t('generic.lang_source') }}:</strong>  {{ item.source }}</span>
                <br/>
                <span class="ml-8"><strong>{{ $t('generic.lang_from') }}:</strong>  {{ new Date(item.date + 'T' + item.time).toLocaleString() }}</span>
                <br/>
                <span class="ml-8"><strong>{{ $t('generic.lang_to') }}:</strong> {{
                    new Date(new Date(item.date + 'T' + item.time).getTime() + parseInt(item.max_time) * 1000).toLocaleString()
                  }}</span>


              </span>
                      </div>

                      <div class="pb-3">
                        <font-awesome-icon :icon="['fal', 'hashtag']" class="text-muted" size="2x"
                                           style="font-size: 15px"/>
                        <span class="text--black ml-5 font-weight-bold text-primary">
                {{ item.code }}
              </span>
                      </div>

                      <div class="pb-3">
                        <font-awesome-icon :icon="['fal', 'users']" class="text-muted" size="2x"
                                           style="font-size: 15px"/>
                        <span class="text--black ml-5">
                {{ item.person }}
              </span>
                      </div>


                      <div class="pb-3">
                        <font-awesome-icon :icon="['fal', 'keynote']" class="text-muted" size="2x"
                                           style="font-size: 15px"/>
                        <span class="text&#45;&#45;black ml-5">
                {{ item.tables.map((elt) => elt.name).join(',') }}
              </span>
                      </div>

                      <div class="pb-3">
                        <font-awesome-icon :icon="['fal', 'phone']" class="text-muted" size="2x"
                                           style="font-size: 15px"/>
                        <span class="text--black ml-5">
                {{ item.phone }}
              </span>
                      </div>

                      <div class="pb-3">
                        <font-awesome-icon :icon="['fal', 'envelope']" class="text-muted" size="2x"
                                           style="font-size: 15px"/>
                        <span class="text--black ml-5">
                {{ item.email }}
              </span>
                      </div>

                      <div>
                        <font-awesome-icon :icon="['fal', 'sticky-note']" class="text-muted" size="2x"
                                           style="font-size: 15px"/>
                        <span class="text-black ml-5">
                  {{ item.note }}
              </span>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>

              <template v-slot:item.created_at="{item}">
            <span>
                          <font-awesome-icon :icon="['fal', 'calendar-alt']" class="text-muted"
                                             size="2x"
                                             style="font-size: 16px"/>
              <span class="font-weight-bold ml-2">{{ item.created_at.split(' ')[0] }}</span>
            </span>
                <br/>
                <span>
                          <font-awesome-icon :icon="['fal', 'clock']" class="text-muted"
                                             size="2x"
                                             style="font-size: 16px"/>
              <span class="font-weight-bold ml-2">{{ item.created_at.split(' ')[1] }}</span>
            </span>
              </template>


              <template v-slot:item.resType_name="{item}">
                <strong>{{ item.resType_name }}</strong>
                <br/>
                <font-awesome-icon v-if="item.note || item.internal_note" :icon="['fal', 'sticky-note']"
                                   class="text-muted"
                                   size="2x"
                                   style="font-size: 16px"/>
                <span class="ml-2">{{
                    item.note && item.note.substring(0, 150) + ' ...' || item.internal_note && item.internal_note.substring(0, 150) + ' ...'
                  }}</span>
              </template>


            </v-data-table>
          </v-col>
        </v-row>
        <v-dialog v-model="email_dialog" max-width="600px" persistent>
          <v-form ref="emailForm" lazy-validation>
            <div class="card">
              <div class="card-header">
                {{ $t('generic.lang_email') }}
              </div>

              <div class="card-body">
                <v-row>
                  <v-col class="pt-0 mt-0 pb-0 mb-0" cols="12">
                    <v-text-field v-model="email_form.subject" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :rules="[v => !!v]" clearable
                                  dense :label="$t('settings.lang_mail_subject')" outlined @focus="showTouchKeyboard"/>
                  </v-col>


                  <v-col class="pt-0 mt-0" cols="12">
                    <quill-editor v-model="email_form.message" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :options="quillOptions" :rules="[v => !!v]" @focus="showTouchKeyboard"></quill-editor>
                  </v-col>
                </v-row>
              </div>

              <div class="card-footer text-right pa-1 pr-3">
                <v-spacer/>
                <v-btn class="ma-1" color="error" @click="email_dialog = false">
                  {{ $t('generic.lang_cancel') }}
                </v-btn>
                <v-btn :disabled="email_loader" :loading="email_loader" class="ma-1" color="primary" @click="send">
                  {{$t('support.lang_supportSend')}}
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-dialog>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
          <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                              :accept="hideTouchKeyboard"
                              :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                              :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                              :options="touchKeyboard.options" class="internalWidthExpanded"/>
        </div>
      </v-container>

      <!--
      <v-data-table :headers="headers" :items="this.reservations" :loading="loadData"
                    :no-data-text="this.$t('generic.lang_noReservationsFound')"
                    hide-default-footer>
        <template v-slot:item.date="{item}">
          <strong>{{ item.date }} {{ item.time }}</strong>
        </template>

        <template v-slot:item.name="{item}">
          <strong class="primary&#45;&#45;text">{{ item.name }}</strong>
        </template>

        <template v-slot:item.confirmed="{item}">
          <v-chip v-if="item.confirmed == true" color="success">
            {{ $t('generic.lang_confirmed') }}
          </v-chip>

          <v-chip v-if="item.confirmed == false" color="warning">
            {{ $t('generic.lang_unconfirmed') }}
          </v-chip>
        </template>


        <template v-slot:item.canceled="{item}">
          <v-chip v-if="item.canceled == true" color="error">
            {{ $t('generic.lang_canceled') }}
          </v-chip>

          <v-chip v-if="item.canceled == false" color="success">
            {{ $t('generic.lang_confirmed') }}
          </v-chip>
        </template>
      </v-data-table>-->
    </div>
  </div>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import Template from "../../settings/customerdisplay/Template";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
  faAt,
  faCalendar,
  faCalendarAlt,
  faCalendarEdit,
  faCalendarTimes,
  faCheck,
  faCheckSquare,
  faClipboard,
  faClock,
  faCopy,
  faEdit,
  faEnvelope,
  faHashtag,
  faInfoSquare,
  faKeynote,
  faPhone,
  faStickyNote,
  faTrash,
  faUser,
  faUsers,
  faWindowClose
} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import mixin from "@/mixins/KeyboardMixIns";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';

library.add(faUsers, faClipboard, faClock, faCalendarAlt, faKeynote, faAt, faCalendar, faUser, faPhone, faCopy, faCheckSquare, faTrash, faInfoSquare, faCalendarEdit, faCalendarTimes, faWindowClose, faEnvelope, faEdit, faStickyNote, faCheck, faHashtag);
export default {
  name: "ReservationOverview",
  components: {
    Template,
    quillEditor,
    'font-awesome-icon': FontAwesomeIcon
  },
  data: () => {
    return {
      loadData: false,
      loading: false,
      reservations: [],
      requested_date: "",
      email_form: {
        email: "",
        subject: "",
        message: ""
      },
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
          ],
        },
      },
      email_dialog: false,
      email_loader: false,
    }
  },
  mixins: [mixin],
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('generic.lang_date'),
          value: 'date',
        },
        {
          text: this.$t('generic.lang_constReservationType'),
          value: 'resType_name',
          sortable: false
        },
        {
          text: this.$t('generic.lang_name'),
          value: 'name',
          sortable: false
        },
        {
          text: this.$t('generic.lang_email'),
          value: 'email',
          sortable: false
        },
      ]
    }
  },
  mounted() {
    this.setToday();
    this.loadRes();
  },
  methods: {
    send() {
      if (!this.$refs.emailForm.validate()) return;
      this.email_loader = true;
      this.axios.post(ENDPOINTS.EMAILS.RESERVATIONS.CONTACTS, this.email_form)
          .then((res) => {
            if (res.data.STATUS === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_success'),
                color: "success"
              });

              this.email_dialog = false;
              this.$refs.emailForm.reset();
              this.email_form.message = "";
            } else if (res.data.STATUS === "FAILED") {
              Events.$emit("showSnackbar", {
                message: res.data.msg,
                color: "error"
              });
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.email_loader = false;
      })
    },
    open_email(item) {
      this.email_form.email = item.email;
      this.close(item);
      this.email_dialog = true;
    },
    close(item) {
      let index = this.reservations.indexOf(item);
      item.selectedOpen = false;
      this.reservations[index] = Object.assign({}, item);
      item.selectedOpen = false;
    },
    setToday() {
      let dt = new Date();
      this.requested_date = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    loadRes() {
      this.loadData = true;
      this.axios.post(ENDPOINTS.RESERVATION.ARCHIVE.GET).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.reservations = res.data.reservations;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {

      }).finally(() => {
        this.loadData = false;
      })
    },
  }
}
</script>

<style scoped>
.date {
  display: block;
  width: 190px;
  height: 80px;
  margin: 5px 5px 8px 5px;
  background: #fff;
  text-align: center;
  font-family: 'Helvetica', sans-serif;
  position: relative;
}

.date .binds {
  position: absolute;
  height: 15px;
  width: 150px;
  background: transparent;
  border: 2px solid #999;
  border-width: 0 5px;
  top: -6px;
  left: 0;
  right: 0;
  margin: auto;
}

.date .month {
  background: #555;
  display: block;
  padding: 8px 0;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 2px solid #333;
  box-shadow: inset 0 -1px 0 0 #666;
}

.date .day {
  display: block;
  margin: 0;
  padding: 5px 0;
  height: 35px;
  box-shadow: 0 0 3px #ccc;
  position: relative;
}

.date .day .time {
  font-size: 20px;
}

.date .day::after {
  content: '';
  display: block;
  height: 100%;
  width: 96%;
  position: absolute;
  top: 3px;
  left: 2%;
  z-index: -1;
  box-shadow: 0 0 3px #868484;
}

.date .day::before {
  content: '';
  display: block;
  height: 100%;
  width: 95%;
  position: absolute;
  top: 6px;
  left: 0%;
  z-index: -1;
  box-shadow: 0 0 3px #ccc;
}

.copy, .actions {
  cursor: pointer;
}
</style>
